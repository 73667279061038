<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <div class="mb-40">{{ policyShowcase }}</div>
  <div class="block__control">
    <div class="block__field">
      <VSearch
        name="searchAdmin"
        placeholder="Поиск"
        class-input="input-border-all input-padding-25 input-height-48"
        v-model="input"
      />

      <VFilter
        @onFilter="onSubmit"
        @onReset="onReset"
      >
        <template v-slot:default>
          <VDateInput
            class="mb-40"
            name="CreatedAt"
            placeholder="Дата создания"
            v-model="dateCreate"
            :is-min="false"
            @input="onDateInput"
          />
          <VDateInput
            class="mb-40"
            name="StartedAt"
            placeholder="Дата начала"
            v-model="dateStart"
            :is-min="false"
            @input="onDateInput"
          />
          <VDateInput
            class="mb-40"
            name="PublishedAt"
            placeholder="Дата публикации"
            v-model="datePublished"
            @input="onDateInput"
            :is-min="false"
          />
          <VDateInput
            class="mb-40"
            name="Duration"
            placeholder="Дата окончания"
            v-model="dateDuration"
            :is-min="false"
            @input="onDateInput"
          />
          <VSelect
            v-model="filterFormData.CompanyId"
            placeholder="Компания"
            name="CompanyId"
            :options="companies"
          />
          <VSelect
            v-model="filterFormData.ResponsibleFullName"
            placeholder="Организатор"
            name="ResponsibleFullName"
            :options="organizers"
          />
        </template>
      </VFilter>
    </div>
    <div class="admin__button">
      <VToggleDisplay
        v-if="isToggleDisplay"
        :class="['mr-20', classToggleDisplay]"
        @set-display="setDisplay"
      />
    </div>
  </div>

  <VTradeProcedure
    :is-chosen-icon="false"
    :request-is-auth="false"
    :is-checkbox="false"
    :url-set-rows="urlSetRows"
    :url-action-query="urlActionQuery"
    class-toggle-display="my-trade__toggle-display--admin"
  />
</template>

<script>
import VSearch from '@/components/ui/form/Search/VSearch'
import { securityTrade } from '@/utils/tabs'
import VFilter from '@/components/ui/modal/filter/VFilter'
import VDateInput from '@/components/ui/form/VDateInput'
import { ref } from 'vue'
import { itemProps } from '@/mixin/props'
import { useFilter } from '@/use/Base/useFilter'
import VTradeProcedure from '../TradeProcedure/VTradeProcedure'
import { useStore } from 'vuex'
import VToggleDisplay from '../../ui/buttons/VToggleDisplay'
import { POLICY_TEXT } from '@/utils/text/policy'
import VSelect from '@/components/ui/form/VSelect.vue'
import { useShowcaseTradeFilterUsers } from '@/use/trade/useTradeFilterUsers'
import { useRoute } from 'vue-router'

export default {
  mixins: [itemProps],
  props: {
    title: {
      type: String,
      required: true
    },
    isToggleDisplay: {
      type: Boolean,
      required: false,
      default: true
    },
    classToggleDisplay: {
      type: String,
      required: false
    }
  },
  setup () {
    const store = useStore()
    const setDisplay = (value) => {
      store.commit('display/setDisplay', value)
    }
    const dateCreate = ref(null)
    const datePublished = ref(null)
    const dateDuration = ref(null)
    const dateStart = ref(null)
    const dateInput = []

    const onDateInput = (name, value) => {
      dateInput.push([name, value])
    }

    const route = useRoute()

    const {
      filterFormData,
      organizers,
      companies,
      fillFilter,
      resetFilter
    } = useShowcaseTradeFilterUsers({ isNew: route.name.includes('new') })

    const filter = () => {
      const filter = []
      for (let i = 0; i < dateInput.length; i++) {
        const filterName = dateInput[i][0]
        const date = dateInput[i][1].toISOString().split('T')
        filter.push({ type: filterName, value: date[0] })
      }
      fillFilter(filter)
      return filter
    }

    const reset = () => {
      dateCreate.value = null
      datePublished.value = null
      dateDuration.value = null
      dateStart.value = null
      resetFilter()
    }

    return {
      setDisplay,
      onDateInput,
      dateCreate,
      datePublished,
      dateStart,
      dateDuration,
      ...useFilter(filter, reset),
      securityTrade,
      policyShowcase: POLICY_TEXT.showcase,
      filterFormData,
      organizers,
      companies
    }
  },
  components: {
    VSelect,
    VToggleDisplay,
    VSearch,
    VFilter,
    VDateInput,
    VTradeProcedure
  }
}
</script>
